import React from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from "../contexts/AppContext"
import { queryServer } from "../utils/queryServer"
import { useLocalLanguage } from "../utils/useLocalLanguage"
import { DEFAULT_COUNTRY } from "../utils/ServiceLocal"
import { validate, COUNTRIES, CountryId } from '../utils/Common/Common'
import { ENVIRONMENT_CALCULATED } from '../utils/constants'
import { assertNever, checkNotNull } from '../utils/utilFunctions'
import Select from 'react-select'
import { FormGroup } from 'reactstrap'
import { Loader } from '../utils/Loader'

// flags copied from flagpedia.net/emoji
const COUNTRY_FLAG: Record<CountryId, string> = {
    'eng-test1': '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    'eng-dev': '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    'eng-test2': '🇬🇧',
    'srb-test1': '🇷🇸',
    'srb-dev': '🇷🇸',
    'srbija-001': '🇷🇸',
}

const calculateAvailableCountriesForRegistration = () => {
    if (ENVIRONMENT_CALCULATED === 'dev') {
        return Object.values(COUNTRIES)
    } else if (ENVIRONMENT_CALCULATED === 'prod') {
        return Object.values(COUNTRIES).filter(it => !!it.allow_register_on_production)
    } else {
        assertNever(ENVIRONMENT_CALCULATED)
    }
}

export function Register() {
    const { inLocalLanguage } = useLocalLanguage()
    const { gCountryId, gSetCountryId } = React.useContext(AppContext)

    const [message, setMessage] = React.useState<JSX.Element>()

    const [email, setEmail] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [inputPasswordType, setInputPasswordType] = React.useState<'password' | 'text'>('password')
    const [firstname, setFirstname] = React.useState<string>('')
    const [lastname, setLastname] = React.useState<string>('')

    const availableCountries = React.useMemo(() => {
        return calculateAvailableCountriesForRegistration()
    }, [])

    // this is used for the error handling (in order to avoid red borders on the initial mount)
    const [clickedRegisterForTheFirstTime, setClickedRegisterForTheFirstTime] = React.useState(false)

    const [loading, setLoading] = React.useState(false)

    const inputPasswordTypeToggle = () => {
        if (inputPasswordType === 'password') {
            setInputPasswordType('text')
        } else {
            setInputPasswordType('password')
        }
    }

    const register = async () => {
        if (loading) {
            return
        }

        if (!clickedRegisterForTheFirstTime) {
            setClickedRegisterForTheFirstTime(true)
        }

        const validPassword = validate(password, 'password')
        const validFirstname = validate(firstname, 'firstname')
        const validLastname = validate(lastname, 'lastname')
        const validEmail = validate(email, 'email')

        if (!validPassword || !validEmail || !validFirstname || !validLastname) {
            setMessage(<div>
                {!validFirstname && <div>- {inLocalLanguage("Firstname field cannot be empty")}</div>}
                {!validLastname && <div>- {inLocalLanguage("Lastname field cannot be empty")}</div>}
                {!validEmail && <div>- {inLocalLanguage("Email should be in valid format")}</div>}
                {!validPassword && <div>- {inLocalLanguage("Password length needs to be at least 8 characters long")}</div>}
            </div>)
        } else {
            setMessage(undefined)
            try {
                setLoading(true)

                await queryServer(
                    'register',
                    {
                        firstname,
                        lastname,
                        email,
                        password,
                        countryId: gCountryId,
                    },
                )

                setMessage(<div
                    className='mg-t-20'
                    style={{ color: 'green', borderLeft: "2px solid #ced4da", paddingLeft: "8px" }}
                >
                    <h5>{inLocalLanguage("Successfully created account")}</h5>
                    <div className='mg-t-10'>
                        <p>
                            {inLocalLanguage("Validate your account by clicking the link we sent you at")} <b>{email}</b>
                        </p>
                        <p style={{ color: "black" }}>
                            {inLocalLanguage("check-verification-email-at-spam")}
                        </p>
                    </div>
                </div>)
                setFirstname('')
                setLastname('')
                setEmail('')
                setPassword('')
                setClickedRegisterForTheFirstTime(false)
            } catch (err: any) {
                setMessage(<div style={{ color: 'red' }}>
                    {err.message ?? inLocalLanguage('Unknown register error. Please try again later.')}
                </div>)
            } finally {
                setLoading(false)
            }
        }
    }

    let content
    if (availableCountries.length === 0) {
        content = <div>
            <h4>Uskoro ce ti biti dostupna registracija.</h4>
            <h6>Trenutno samo osobe sa ovlascenjem imaju pristup aplikaciji.</h6>
        </div>
    } else {
        content = <>
            <h2 className="slim-logo logo-font" style={{ color: '#1b84e7', marginBottom: 20, fontSize: "52px" }}>Lako je</h2>
            <h3 className="signin-title-primary mg-b-20">{inLocalLanguage('Register')}</h3>

            <FormGroup>
                <Select
                    isSearchable={false}
                    options={availableCountries}
                    value={checkNotNull(availableCountries.find(country => country.id === gCountryId), 'co9ospS9')}
                    getOptionLabel={(option) => `${COUNTRY_FLAG[option.id]} ${option.country_in_local}`}
                    onChange={option => gSetCountryId(option?.id ?? DEFAULT_COUNTRY)}
                    isOptionSelected={option => option.id === gCountryId}
                    placeholder="Chose a country"
                    styles={{
                        control: (base) => ({
                            ...base,
                            fontSize: "16px",
                        }),
                        option: (base) => ({
                            ...base,
                            fontSize: "16px",
                        }),
                    }}
                />
            </FormGroup>

            <div className="row row-xs mg-b-10 mg-t-10">
                <input
                    type="text"
                    className="form-control"
                    placeholder={inLocalLanguage('Firstname')}
                    value={firstname}
                    onChange={e => setFirstname(e.target.value)}
                    style={{
                        fontSize: "16px",
                        border: !validate(firstname, 'firstname') && clickedRegisterForTheFirstTime ? "1px solid red" : undefined,
                    }}
                />
            </div>

            <div className="row row-xs mg-b-10 mg-t-10">
                <input
                    type="text"
                    className="form-control"
                    placeholder={inLocalLanguage('Lastname')}
                    value={lastname}
                    onChange={e => setLastname(e.target.value)}
                    style={{
                        fontSize: "16px",
                        border: !validate(lastname, 'lastname') && clickedRegisterForTheFirstTime ? "1px solid red" : undefined,
                    }}
                />
            </div>

            <div className="row row-xs mg-b-10">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value.trim())}
                    style={{
                        fontSize: "16px",
                        border: !validate(email, 'email') && clickedRegisterForTheFirstTime ? "1px solid red" : undefined,
                    }}
                />
            </div>

            <div className="row row-xs mg-b-10" style={{ position: 'relative' }}>
                <input
                    type={inputPasswordType}
                    className="form-control"
                    placeholder={inLocalLanguage('Password')}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    style={{
                        fontSize: "16px",
                        border: !validate(password, 'password') && clickedRegisterForTheFirstTime ? "1px solid red" : undefined,
                    }}
                />
                <span onClick={inputPasswordTypeToggle} style={{ position: 'absolute', top: '4px', right: '8px', padding: '8px', fontSize: '110%', cursor: 'pointer' }}>
                    {inputPasswordType === "password" ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                </span>
            </div>

            {message && <div className='mg-b-10'>{message}</div>}

            <button
                className="btn btn-primary btn-block btn-signin mg-t-30"
                onClick={register}
            >
                {inLocalLanguage('Register')}
                {loading && <Loader
                    className='mg-l-10'
                    style={{ width: '15px', height: '15px' }}
                />}
            </button>
        </>
    }

    return (
        <div className="signin-wrapper app-background">
            <div className="signin-box signup">
                {content}

                <hr />

                <p className="mg-t-20 mg-b-0">
                    {inLocalLanguage('Have account?')} <Link to="/login">{inLocalLanguage('Login')}</Link>
                </p>
            </div>
        </div>
    )
}
