import React from "react"
import { Container } from "../utils/Container"
import { AppContext } from "../contexts/AppContext"
import { queryServer } from "../utils/queryServer"
import { getProfileImageURL, onProfileImageError } from "../Header/Header"
import { useNavigate, useParams } from 'react-router-dom'
import { check } from '../utils/utilFunctions'
import { RequestResponseTypes } from "../utils/Common/RequestResponseTypes"
import { FormGroup, Input, Label } from "reactstrap"
import queryString from 'query-string'
import { ENVIRONMENT_CALCULATED } from "../utils/constants"

const EditAboutMe = ({
    aboutMe,
    onSave,
    onCancel,
}: {
    aboutMe: string
    onSave: (about: string) => Promise<void>
    onCancel: () => void
}) => {
    const [newAboutMe, setNewAboutMe] = React.useState(aboutMe)

    return <div>
        <FormGroup>
            <Label for="timeSolving">Write something about yourself</Label>
            <Input
                type="textarea"
                name="text"
                id="blah"
                value={newAboutMe}
                onChange={e => setNewAboutMe(e.target.value)}
                placeholder="Write something about yourself..."
            />
        </FormGroup>

        <div>
            <span
                onClick={() => onSave(newAboutMe)}
                className="span-btn"
            >
                Save
            </span>
            <span
                onClick={onCancel}
                className="span-btn"
            >
                Cancel
            </span>
        </div>
    </div>
}

const ProfilePageX = ({ userId }: { userId: string }) => {
    const [userInfo, setUserInfo] = React.useState<RequestResponseTypes['userProfileInfo']['response']>()
    const [editingAboutMe, setEditingAboutMe] = React.useState(false)
    const { gMyId } = React.useContext(AppContext)

    const navigate = useNavigate()

    React.useEffect(() => {
        getProfilePageInfo(userId)
    }, [userId])

    const getProfilePageInfo = async (userId: string) => {
        setEditingAboutMe(false)
        setUserInfo(undefined)
        const response = await queryServer(
            'userProfileInfo',
            { userId },
        )
        setUserInfo(response)
    }

    const updateAboutMe = async (about: string) => {
        const response = await queryServer(
            'updateAboutMe',
            { aboutMe: about }
        )
        setUserInfo(prev => {
            check(prev != undefined, 'Ppdsdsds')
            return {
                about: response.aboutMe,
                id: prev.id,
                firstName: prev.firstName,
                lastName: prev.lastName,
            }
        })
        setEditingAboutMe(false)
    }

    const renderAboutMe = (user: RequestResponseTypes['userProfileInfo']['response']) => {
        if (editingAboutMe) {
            return <EditAboutMe
                aboutMe={user.about}
                onSave={updateAboutMe}
                onCancel={() => setEditingAboutMe(false)}
            />
        } else {
            return <div style={{ display: 'flex' }}>
                <p className="card-profile-position">{user.about}</p>

                {user.id === gMyId && <div>
                    <span className="span-btn" onClick={() => setEditingAboutMe(true)}>Edit</span>
                </div>}
            </div>
        }
    }

    let content
    if (userInfo == null) {
        content = <h4>Please wait...</h4>
    } else {
        content = (
            <div className="row row-sm">
                <div className="col-lg-12">
                    <div className="card card-profile">
                        <div className="card-body">
                            <div className="media">
                                <div>
                                    <img src={getProfileImageURL(userId)} onError={onProfileImageError} alt="profile image" />
                                    {gMyId !== userId && <div className="mg-t-20">
                                        <span
                                            className="span-btn"
                                            onClick={() => {
                                                const params = queryString.stringify({
                                                    userId,
                                                    firstname: userInfo.firstName,
                                                    lastname: userInfo.lastName,
                                                })
                                                navigate(`/messages/?${params}`)
                                            }}
                                        >
                                            Send message
                                        </span>
                                    </div>}
                                </div>
                                <div className="media-body">
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <h3 className="card-profile-name">{userInfo.firstName} {userInfo.lastName}</h3>
                                    </div>
                                    {renderAboutMe(userInfo)}
                                </div>
                            </div>
                        </div>
                        {/* {userId === gMyId && <div className="card-footer">
                        <label htmlFor="filePicker" style={{ marginLeft: "5px", color: "#1b84e7" }}>
                            {inLocalLanguage('Change profile picture','English')}
                        </label>
                        <input id="filePicker" style={{ visibility: "hidden" }} type={"file"} onChange={uploadProfilePictureHandler} />
                    </div>} */}
                    </div>
                </div>
            </div>
        )
    }

    return <Container
        title='Profile page'
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: true,
        }}
        className="contributors-exercise-page"
    >
        {content}
    </Container>
}

export const ProfilePage = () => {
    const { userId } = useParams()

    if (ENVIRONMENT_CALCULATED !== "dev") {
        return null
    }

    let content
    if (userId == null) {
        content = <h3>Unknown User ID</h3>
    } else {
        content = <ProfilePageX userId={userId} />
    }
    return content
}
