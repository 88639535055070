import React from 'react'
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { ModalContentProps } from '../utils/useModal'
import { useLocalLanguage } from '../utils/useLocalLanguage'
import ReactCrop, { centerCrop, makeAspectCrop, PixelCrop, type Crop } from 'react-image-crop'

type Props = {
    fullImageUrl: string
    onSubmit: (
        croppedImageUrl: string,
        width: number | undefined,
        height: number | undefined,
    ) => void
}

function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

export const ImageCropModal = ({
    params,
    closeModal,
}: ModalContentProps<Props>) => {
    const { fullImageUrl, onSubmit } = params

    const imgRef = React.useRef<HTMLImageElement>(null)
    const [crop, setCrop] = React.useState<Crop>()
    const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>()

    const { inLocalLanguage } = useLocalLanguage()

    const confirm = async () => {
        if (completedCrop != null && imgRef.current != null) {
            const croppedImageUrl = await getCroppedImageUrl()
            onSubmit(croppedImageUrl, completedCrop.width, completedCrop.height)
            closeModal()
        }
    }

    // ChatGPT optimized function from
    // https://codesandbox.io/p/sandbox/react-image-crop-demo-with-react-hooks-y831o?file=%2Fsrc%2FApp.tsx%3A78%2C15
    async function getCroppedImageUrl(): Promise<string> {
        const image = imgRef.current
        if (!image || !completedCrop) {
            throw new Error('Crop canvas does not exist')
        }

        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        const maxDimension = 1000
        const scaleFactor = Math.min(
            maxDimension / (completedCrop.width * scaleX),
            maxDimension / (completedCrop.height * scaleY),
            1,
        )

        const canvas = document.createElement('canvas')
        canvas.width = completedCrop.width * scaleX * scaleFactor
        canvas.height = completedCrop.height * scaleY * scaleFactor
        const ctx = canvas.getContext('2d')
        if (!ctx) throw new Error('No 2d context')

        const imageBitmap = await createImageBitmap(
            image,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY
        )

        ctx.imageSmoothingEnabled = false
        ctx.drawImage(imageBitmap, 0, 0, canvas.width, canvas.height)

        const blob = await new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (blob) resolve(blob)
                    else reject(new Error('Failed to create blob'))
                },
                'image/jpeg',
                1,
            )
        }) as Blob

        return URL.createObjectURL(blob)
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, 16 / 9))
    }

    return <div className='responsive-modal-box' style={{ width: "740px" }}>
        <ModalHeader toggle={closeModal}>Crop and solve exercise</ModalHeader>
        <ModalBody>
            <div style={{ padding: '0 15px', width: '100%' }}>
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                >
                    <img
                        ref={imgRef}
                        src={fullImageUrl}
                        onLoad={onImageLoad}
                        className='mw-100'
                    />
                </ReactCrop>
            </div>
        </ModalBody>
        <ModalFooter>
            <Button color="success" onClick={confirm}>Crop and Solve</Button>
            <Button color="secondary" onClick={closeModal}>{inLocalLanguage("Cancel")}</Button>
        </ModalFooter>
    </div>
}
