import React from 'react'
import { Link } from 'react-router-dom'
import { queryServer, TOKEN_NAME } from '../utils/queryServer'
import { useLocalLanguage } from "../utils/useLocalLanguage"
import { COUNTRIES, CountryId, ACCOUNT_NOT_VALIDATED, ACCOUNT_NOT_VALIDATED_SEPARATOR } from '../utils/Common/Common'
import { useProcessLoginResponse } from '../utils/useProcessLoginResponse'
import { Loader } from '../utils/Loader'
import { AppContext } from '../contexts/AppContext'
import { assertNever, check, checkNotNull } from '../utils/utilFunctions'

export function Login() {
    const { gCountryId } = React.useContext(AppContext)

    const [email, setEmail] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [inputPasswordType, setInputPasswordType] = React.useState<'password' | 'text'>('password')

    const [message, setMessage] = React.useState<JSX.Element>()
    const [showRedBordersAndEmptyMessages, setShowRedBordersAndEmptyMessages] = React.useState(false)

    const [loading, setLoading] = React.useState(false)

    const { inLocalLanguage } = useLocalLanguage()

    const { processLoginResponse } = useProcessLoginResponse()

    const emailIsEmpty = email.trim() === ""
    const passwordIsEmpty = password.trim() === ""

    const inputPasswordTypeToggle = () => {
        if (inputPasswordType === 'password') {
            setInputPasswordType('text')
        } else {
            setInputPasswordType('password')
        }
    }

    const login = async () => {
        if (loading) {
            return
        } else if (emailIsEmpty || passwordIsEmpty) {
            if (!showRedBordersAndEmptyMessages) {
                setShowRedBordersAndEmptyMessages(true)
            }
        } else {
            setMessage(undefined)
            try {
                setLoading(true)

                const loginResponse = await queryServer(
                    'login',
                    {
                        email,
                        password,
                        countryIdFromClientContext: gCountryId,
                    },
                )

                window.localStorage.setItem(TOKEN_NAME, loginResponse.token)

                await processLoginResponse(loginResponse)
            } catch (err: any) {
                const message = err.message
                if (message != null
                    && typeof message === 'string' &&
                    message.includes(ACCOUNT_NOT_VALIDATED)) {
                    processNotValidated(message)
                } else {
                    setMessage(<div style={{ color: 'red' }}>
                        {message ?? inLocalLanguage('Error. Please try again later')}
                    </div>)
                }
            } finally {
                setLoading(false)
            }
        }
    }

    const processNotValidated = (responseMessage: string) => {
        check(responseMessage.includes(ACCOUNT_NOT_VALIDATED), 'cxzlll')
        const arr = responseMessage.split(ACCOUNT_NOT_VALIDATED_SEPARATOR)
        const countryId = arr[0] as CountryId
        const languageInEnglish = checkNotNull(COUNTRIES.find(c => c.id === countryId), 'det4tt4').language_in_english
        const email = arr[1]

        let line1
        if (languageInEnglish === "English") {
            line1 = <li style={{ color: 'red' }}>
                Account not validated. Validate it by clicking the link we sent you at <b>{email}</b>
            </li>
        } else if (languageInEnglish === "Serbian") {
            line1 = <li style={{ color: 'red' }}>
                Nalog nije validiran. Validiraj ga klikom na link koji smo ti poslali na email <b>{email}</b>
            </li>
        } else {
            assertNever(languageInEnglish)
        }

        let line2
        if (languageInEnglish === "English") {
            line2 = <li style={{ color: 'red', paddingRight: "6px" }}>
                If you can't find validation email in your inbox, also check junk/spam mailbox
            </li>
        } else if (languageInEnglish === "Serbian") {
            line2 = <li style={{ color: 'red', paddingRight: "6px" }}>
                Ukoliko ne vidis email u inboxu, proveri i sanduce za nezeljenu postu (spam/junk)
            </li>
        } else {
            assertNever(languageInEnglish)
        }

        let line3
        if (languageInEnglish === "English") {
            line3 = <li style={{ color: 'red', paddingRight: "6px" }}>
                If you still have problems with validation, contact us at email "lakoje.info@gmail.com"
            </li>
        } else if (languageInEnglish === "Serbian") {
            line3 = <li style={{ color: 'red', paddingRight: "6px" }}>
                Ukoliko ti je potrebna pomoc, kontaktiraj nas na email <b>lakoje.info@gmail.com</b>
            </li>
        } else {
            assertNever(languageInEnglish)
        }

        setMessage(<ul style={{ borderLeft: "2px solid #ced4da" }}>
            {line1}
            {line2}
            {line3}
        </ul>)
    }

    return (
        <div className="signin-wrapper app-background">
            <div
                className="signin-box"
                onKeyUp={(e) => {
                    if (e.key === "Enter") {
                        login()
                    }
                }}
            >
                <h2 className="slim-logo logo-font" style={{ color: '#1b84e7', marginBottom: 20, fontSize: "52px" }}>Lako je</h2>
                <h3 className="signin-title-primary mg-b-20">{inLocalLanguage('Login')}</h3>

                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={inLocalLanguage('Enter your email')}
                        value={email}
                        onChange={e => setEmail(e.target.value.trim())}
                        style={{
                            fontSize: "16px",
                            border: showRedBordersAndEmptyMessages && emailIsEmpty ? "1px solid red" : undefined
                        }}
                    />
                    {showRedBordersAndEmptyMessages && emailIsEmpty && <span style={{ fontSize: "92%" }}>
                        {inLocalLanguage('Email is required')}
                    </span>}
                </div>
                <div className="form-group" style={{ position: 'relative' }}>
                    <input
                        type={inputPasswordType}
                        className="form-control"
                        placeholder={inLocalLanguage('Enter your password')}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        style={{
                            fontSize: "16px",
                            border: showRedBordersAndEmptyMessages && passwordIsEmpty ? "1px solid red" : undefined
                        }}
                    />
                    <span onClick={inputPasswordTypeToggle} style={{ position: 'absolute', top: '4px', right: '8px', padding: '8px', fontSize: '110%', cursor: 'pointer' }}>
                        {inputPasswordType === "password" ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                    </span>
                    {showRedBordersAndEmptyMessages && passwordIsEmpty && <span style={{ fontSize: "92%" }}>
                        {inLocalLanguage('Password is required')}
                    </span>}
                </div>

                {message && <div className='mg-b-10'>{message}</div>}

                <button className="btn btn-primary btn-block btn-signin mg-t-20" onClick={login}>
                    {inLocalLanguage('Login')}
                    {loading && <Loader
                        className='mg-l-10'
                        style={{ width: '15px', height: '15px' }}
                    />}
                </button>
                <hr />
                <p className="mg-b-0" style={{ fontSize: "92%" }}>
                    <Link to="/register">{inLocalLanguage('Register')}</Link>
                </p>
                <p style={{ fontSize: "92%" }} className="mg-b-0 mg-t-10">
                    <Link to="/forgot-password">{inLocalLanguage("Forgot password")}?</Link>
                </p>
            </div>
        </div>
    )
}
