import React from 'react'
import { Container } from "../utils/Container"
import { useDropzone } from 'react-dropzone'
import 'react-image-crop/dist/ReactCrop.css'
import { useModal } from '../utils/useModal'
import { ImageCropModal } from './ImageCropModal'
import { useImageProcessor } from '../utils/useImageProcessor'
import { RenderProcessedImageResult } from './RenderProcessedImageResult'
import { assertNever } from '../utils/utilFunctions'

export const ScanExercise = () => {
    const cropImageModal = useModal(ImageCropModal)

    const imageProcessor = useImageProcessor(
        () => <h5>Please wait...</h5>,
        (result) => <RenderProcessedImageResult result={result} />,
        ({ imgURL, height, width }) => {
            return <>
                <hr />
                <div className='d-flex mg-t-20 flex-column align-items-center'>
                    <img
                        alt="Crop preview"
                        src={imgURL}
                        className='mw-100'
                        width={width}
                        height={height}
                        style={{ border: '1px solid #6c757d' }}
                    />
                </div>
            </>
        },
    )

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            cropImageModal.show({
                fullImageUrl: acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))[0].preview,
                onSubmit: (imgURL, width, height) => imageProcessor.process({
                    imgURL,
                    width,
                    height,
                }),
            })
        },
    })

    let algorithm
    if (imageProcessor.algorithm === 'ai') {
        algorithm = "AI"
    } else if (imageProcessor.algorithm === 'vision') {
        algorithm = "Vision"
    } else {
        assertNever(imageProcessor.algorithm)
    }

    return <Container
        title={"Contribute"}
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: true,
        }}
    >
        <div className='mg-b-20 mg-l-15'>
            Exercise will be solved by <span style={{ color: "#1b84e7" }}><b>{algorithm}</b></span> <span
                className='span-btn'
                onClick={() => imageProcessor.setAlgorithm(prev => {
                    if (prev === 'ai') {
                        return 'vision'
                    } else if (prev === 'vision') {
                        return 'ai'
                    } else {
                        assertNever(prev)
                    }
                })}
            >Toggle</span>
        </div>
        <section className="container">
            <div
                {...getRootProps({ className: 'dropbox' })}
                style={{ borderColor: `${isDragAccept ? "#00e676" : isDragReject ? "#ff1744" : "#1b84e7"}` }}
            >
                <input {...getInputProps()} />
                {
                    isDragActive
                        ? <p>Drop the image here...</p>
                        : <p>
                            Drag and drop or click to select image of a math exercise
                        </p>
                }
            </div>
        </section>
        {imageProcessor.render()}
        {cropImageModal.render()}
    </Container>
}
